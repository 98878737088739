export const realtorData = {
  email: 'GCARRASCO@UNNE.CL',
  phone:'+56931895572'
  // email: 'contacto@accionpanal.com',
};

export const footerData = {
  email: 'GCARRASCO@UNNE.CL',
  phone:'931895572',
  wsp:'931895572',
}
